import { Container, Typography } from '@mui/material';

import { ImageAlbum } from '../components';

const Portfolio = () => {
    return (
        <Container sx={{
            marginTop: '80px',
            marginBottom: '100px'
        }} maxWidth="xl">
            <Typography variant={'h5'} sx={{ marginBottom: 3 }}>Galeria</Typography>
            <ImageAlbum />
        </Container>
    )
}

export default Portfolio;