import { Container, Typography } from '@mui/material'

import { OffersList } from '../components';

const OffersPage = () => {
    return (
        <Container maxWidth={'xl'} sx={{ marginTop: '80px', marginBottom: '100px', }}>
            <Typography variant={'h5'} sx={{ marginBottom: 3 }}>Oferta</Typography>
            <Typography sx={{ marginBottom: 2 }}>
                <b>WAŻNE!</b> Podane w ofertach ceny dotyczą sesji jednego lub dwóch zwierząt - jeśli posiadasz więcej pupili skontaktuj się ze mną w celu ustalenia ceny.
            </Typography>
            <OffersList />
        </Container>
    )
}

export default OffersPage;