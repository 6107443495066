import { useState } from 'react';
import { ImageList, ImageListItem } from '@mui/material';

import { gallery } from './image-list';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const ImageAlbum = () => {
    const [index, setIndex] = useState(-1);

    return (
        <>
            <ImageList cols={3} rowHeight={400} gap={10} sx={{
                gridTemplateColumns:
                    'repeat(auto-fill, minmax(400px, 1fr))!important',
            }}>
                {gallery.map((item, index) => (
                    <ImageListItem
                        key={item.src}
                        onClick={() => setIndex(index)}
                        sx={{
                            height: '100% !important',
                            opacity: '1',
                            transition: 'opacity .3s linear',
                            cursor: 'pointer',
                            '&:hover': { opacity: '0.8' },
                        }}>
                        <img
                            src={`${item.src}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${item.src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt={'gallery-item'}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}

                <Lightbox
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    slides={gallery}
                />
            </ImageList>
        </>
    );
}

export default ImageAlbum;