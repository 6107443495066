import { useState } from 'react';

import { styled } from '@mui/material/styles';
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Typography,
  Paper
} from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  cardMediaImg: string;
  offerTitle: string;
  offerPrice: number | string;
  offerDetails: React.ReactNode;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const OfferItem = (props: Props) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Paper sx={{
      maxWidth: { xs: '100%', sm: '100%', md: '30%' },
      minWidth: { md: '380px' },
      mx: { md: 1 },
      my: { xs: 2, sm: 2, md: 2 }
    }} elevation={8}>
      <Card >
        <CardMedia
          component={'img'}
          height={'300'}
          image={props.cardMediaImg}
          alt={'offerImg'}
        />
        <CardContent>
          <Typography gutterBottom variant='h6' component='div'>
            {props.offerTitle}
          </Typography>
          <Typography variant='subtitle1'  >
            {typeof props.offerPrice === 'number' ? `${props.offerPrice} zł` : props.offerPrice}
          </Typography>
        </CardContent>
        <CardActions>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label={'show more'}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent>
            {props.offerDetails}
          </CardContent>
        </Collapse>
      </Card>
    </Paper>
  );
}

export default OfferItem;
