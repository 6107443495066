import { List, Box } from '@mui/material'

import { MenuLink, MenuLinkMobile } from '.'

interface Props {
    isMobile: boolean;
}

export const Menu = (props: Props) => {
    return (
        props.isMobile ? (
            <List>
                <MenuLinkMobile linkTxt={'O mnie'} linkTo={'/#/about'} />
                <MenuLinkMobile linkTxt={'Portfolio'} linkTo={'/#/portfolio'} />
                <MenuLinkMobile linkTxt={'Oferta'} linkTo={'/#/offers'} />
                <MenuLinkMobile linkTxt={'Kontakt'} linkTo={'/#/contact'} />
            </List>
        ) : (
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <MenuLink linkTxt={'O mnie'} linkTo={'/#/about'} />
                <MenuLink linkTxt={'Portfolio'} linkTo={'/#/portfolio'} />
                <MenuLink linkTxt={'Oferta'} linkTo={'/#/offers'} />
                <MenuLink linkTxt={'Kontakt'} linkTo={'/#/contact'} />
            </Box>
        )
    )
}

export default Menu