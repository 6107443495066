import { Box } from '@mui/material';

import OfferItem from './OfferItem';

import { offers } from './offers';

const OffersList = () => {
    return (
        <Box sx={{
            display: { xs: 'block', sm: 'block', md: 'flex' },
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexWrap: 'wrap'
        }}>
            {
                offers.map(offer => <OfferItem
                    cardMediaImg={offer.mediaSrc}
                    offerTitle={offer.title}
                    offerPrice={offer.price}
                    offerDetails={offer.details} />)
            }
        </Box>
    )
}

export default OffersList;