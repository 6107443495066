import Button from '@mui/material/Button'

interface Props {
    linkTo: string;
    linkTxt: string;
}

export const MenuLinkMobile = (props: Props) => {
    return (
        <Button sx={{ color: '#000' }} href={props.linkTo}>
            {props.linkTxt}
        </Button>
    )
}

export default MenuLinkMobile