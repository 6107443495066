import { Box, Typography } from '@mui/material';
import FooterContactInfo from './FooterContactInfo';
import SocialMediaIcons from '../../SocialMediaIcons';

const FooterContent = () => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                <FooterContactInfo />
                <SocialMediaIcons iconSize={'medium'} />
            </Box>
            <Box>
                <Typography sx={{ position: { md: 'fixed' }, bottom: { md: 27 }, left: { md: 20 } }} variant={'caption'}>&copy; 2022, Lady Pet Studio</Typography>
            </Box>
        </Box>
    )
}

export default FooterContent