import { Box, Typography } from "@mui/material";

import studio1 from '../../../assets/img/gallery/img00.jpg'
import studio2 from '../../../assets/img/studio2.jpg'
import voucher from '../../../assets/img/voucher.png'
import plener from '../../../assets/img/plener.jpg'

export const offers = [
    {
        title: 'Sesja w studio - pakiet standard',
        price: 400,
        mediaSrc: studio1,
        details: <Box sx={{ width: '100%' }}>
            <Typography paragraph>
                Studyjna sesja w pakiecie standard to wybór idealny jeśli to pierwsza profesjonalna sesja Twojego pupila w studio.
                <br /><br />
                <b>Czas trwania sesji:</b> 30-40 min
                <br />
                <b>Ilość zdjęć w pakiecie:</b> 5 zdjęć
                <br />
                <b>Kolor tła:</b> zdjęcia wykonywane na jednym kolorze*
                <br /><br />
                Istnieje możliwość dokupienia dodatkowych ujęć w cenie 40zł za sztukę.
                <br />
            </Typography>
            <Typography variant={'caption'}>*kolor tła może zostać ustalony przed rozpoczęciem sesji</Typography>
        </Box>
    },
    {
        title: 'Sesja w studio - pakiet rozszerzony',
        price: 600,
        mediaSrc: studio2,
        details: <Box sx={{ width: '100%' }}>
            <Typography paragraph>
                Rozszerzony pakiet sesji w studio to ciekawa propozycja dla zaprawionych w boju pupilowych modeli.
                Jeśli Twój zwierzak w każdych warunkach czuje się jak ryba w wodzie i w dodatku pozowanie to dla niego
                mały smaczek - wybór tej opcji będzie jak znalazł.
                <br /><br />
                <b>Czas trwania sesji:</b> 45-60 min
                <br />
                <b>Ilość zdjęć w pakiecie:</b> 7 zdjęć
                <br />
                <b>Kolor tła:</b> 2 kolory*
                <br /><br />
                Istnieje możliwość dokupienia dodatkowych ujęć w cenie 40zł za sztukę.
                <br />
            </Typography>
            <Typography variant={'caption'}>*kolory tła mogą zostać ustalone przed rozpoczęciem sesji</Typography>
        </Box>
    },
    {
        title: 'Sesja w plenerze',
        price: 500,
        mediaSrc: plener,
        details: <Box sx={{ width: '100%' }}>
            <Typography paragraph>
                Zdjęcia w plenerze cieszą się dużą popularnością wśród miłośników zwierząt. Każdy psiak wygląda pięknie wśród bajecznej scenerii.
                Jest to także dobry wybór jeśli Twój pupil jest lękliwy i boi się nietypowych dźwięków (np. błysku lampy czy szelestu kartonowego tła).
                <br /><br />
                <b>Czas trwania sesji:</b> 45-75 min
                <br />
                <b>Ilość zdjęć w pakiecie:</b> 5 zdjęć
                <br />
                <b>Miejsca i czas:</b> do ustalenia*
                <br /><br />
                Istnieje możliwość dokupienia dodatkowych ujęć w cenie 40zł za sztukę.
                <br />
            </Typography>
            <Typography variant={'caption'}>*za dojazd na terenie Trójmiasta nie pobieram opłat. W przypdaku wyboru miejsca poza granicami aglomeracji -
                cena do ustalenia.</Typography>
        </Box>
    },
    {
        title: 'Voucher na sesję',
        price: 'cena zgodnie z wybraną opcją',
        mediaSrc: voucher,
        details: <Box sx={{ width: '100%' }}>
            <Typography paragraph>
                Spraw bliskiej osobie radość z niepowtarzalnych zdjęć pupila.
                Voucher na sesję to idealny pomysł na prezent - nawet zupełnie bez okazji! 
                <br/><br/>
                <b>Forma:</b> elektroniczny/drukowany
            </Typography>
        </Box>
    },
]