import { Container, Box, Paper, Typography } from '@mui/material';

import { Map, ContactData } from '../components';

const ContactPage = () => {
    return (
        <Container maxWidth={'xl'} sx={{ marginTop: '80px', marginBottom: '100px', }}>
            <Typography variant={'h5'} sx={{ marginBottom: 3 }}>Kontakt</Typography>
            <Box sx={{
                display: { xs: 'block', sm: 'block', md: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                <Box sx={{
                    marginBottom: { xs: 3, sm: 3 },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Paper sx={{
                        width: { xs: '100%', sm: '100%', md: '800px' },
                        height: { xs: '250px', sm: '400px', md: '600px' },
                        padding: 1
                    }} elevation={8}>
                        <Map />
                    </Paper>
                </Box>
                <Box sx={{ marginLeft: { md: 4 }, width: { xs: '100%', sm: '100%', md: '40%' } }}>
                    <ContactData />
                </Box>
            </Box>
        </Container >
    )
}

export default ContactPage;