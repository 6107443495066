import { useNavigate } from 'react-router-dom';

import { Typography, Drawer, Divider, Box } from '@mui/material';

import { Menu } from './Menu';

import logo from '../../../assets/img/logo.png';

interface Props {
    isOpen: boolean;
    handleToggle: () => void;
}

const drawerWidth = 240

export const DrawerBar = (props: Props) => {
    const navigate = useNavigate();
    const goHome = () => navigate('/');

    const drawer = (
        <Box onClick={props.handleToggle} sx={{ textAlign: 'center' }}>
            <Box onClick={goHome} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                <img alt={'logo'} src={logo} width={'35px'} height={'35px'} style={{ marginLeft: '10px' }} />
                <Typography variant='h6' sx={{ my: 2, ml: 2, fontSize: 'large', fontFamily: 'Nothing You Could Do, cursive' }}>
                    Lady Pet Studio
                </Typography>
            </Box>
            <Divider />
            <Menu isMobile={true} />
        </Box>
    );

    return (
        <Box component='nav'>
            <Drawer
                variant='temporary'
                open={props.isOpen}
                onClose={props.handleToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

export default DrawerBar
