import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Toolbar, Typography, IconButton, Box, AppBar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { Menu } from './components/Menu';
import { DrawerBar } from './components/Drawer';

import { theme } from '../../theme/theme';

import logo from '../../assets/img/logo.png';

export const Header: React.FC = () => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const navigate = useNavigate();

    const handleDrawerToggle: () => void = () => {
        setMobileOpen(!mobileOpen)
    };

    const goHome = () => navigate('/');

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar component='nav' sx={{
                backgroundColor: theme.palette.white?.main
            }}>
                <Toolbar>
                    <Typography
                        variant='h6'
                        component='div'
                        onClick={goHome}
                        sx={{ cursor: 'pointer', flexGrow: 1, fontSize: 'x-large', fontFamily: 'Nothing You Could Do, cursive', display: 'block' }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img alt={'logo'} src={logo} width={'35px'} height={'35px'} style={{ marginRight: '10px' }} />
                            Lady Pet Studio
                        </Box>
                    </Typography>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        onClick={handleDrawerToggle}
                        sx={{ ml: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu isMobile={false} />
                </Toolbar>
            </AppBar>
            <Box component='nav'>
                <DrawerBar isOpen={mobileOpen} handleToggle={handleDrawerToggle} />
            </Box>
        </Box>
    );
}

export default Header
