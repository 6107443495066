import { Stack, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import { theme } from '../theme/theme';

interface Props {
    iconSize: "small" | "inherit" | "large" | "medium" | undefined;
}

const SocialMediaIcons = (props: Props) => {
    return (
        <Stack direction='row' spacing={0.2}>
            <IconButton aria-label='ig' href='https://www.instagram.com/ladypetstudio/'>
                <InstagramIcon fontSize={props.iconSize} sx={{ color: theme.palette.bckground?.main }} />
            </IconButton>
            <IconButton aria-label='fb' href='https://www.facebook.com/lady.pet.studio'>
                <FacebookIcon fontSize={props.iconSize} sx={{ color: theme.palette.bckground?.main }} />
            </IconButton>
        </Stack>
    )
}

export default SocialMediaIcons;