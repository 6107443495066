import { Route, Routes, useLocation } from 'react-router-dom';

import { Header, Footer } from './components';
import Landing from './modules/Landing/pages/Landing';

import AboutPage from './modules/About/pages/About';
import OffersPage from './modules/Offer/pages/OffersPage';
import Portfolio from './modules/Portfolio/pages/Portfolio';
import ContactPage from './modules/Contact/pages/ContactPage';

const App = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/' ? <Header /> : null}
      <Routes>
        <Route path={'*'} element={<Portfolio />} />
        <Route path={'/'} element={<Landing />} />
        <Route path={'/about'} element={<AboutPage />} />
        <Route path={'/portfolio'} element={<Portfolio />} />
        <Route path={'/offers'} element={<OffersPage />} />
        <Route path={'/contact'} element={<ContactPage />} />
      </Routes>
      {location.pathname !== '/' ? <Footer /> : null}
    </>
  );
}

export default App;
