import { Container, Box, Typography } from '@mui/material'

import { AboutImage, AboutText } from '../components';

import { about1, about2 } from '../components/about-content';

import aboutImg from '../../../assets/img/about.jpg'
import aboutImg2 from '../../../assets/img/about2.jpg'

const AboutPage = () => {
    return (
        <Container maxWidth={'xl'} sx={{ marginTop: '80px', marginBottom: '100px', }}>
            <Typography variant={'h5'} sx={{ marginBottom: 3 }}>Poznajmy się!</Typography>
            <Box sx={{
                display: { xs: 'block', sm: 'block', md: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                <Box sx={{ marginRight: { md: 4 }, marginBottom: { xs: 3, sm: 3 }, width: { xs: '100%', sm: '100%', md: '60%' } }}>
                    <AboutImage imgSrc={aboutImg} />
                </Box>
                <Box sx={{ width: { xs: '100%', sm: '100%', md: '35%' } }}>
                    <AboutText txtContent={about1} />
                </Box>
            </Box>
            <Box sx={{
                display: { xs: 'block', sm: 'block', md: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                <Box sx={{ marginTop: { xs: 3, sm: 3, md: 3 }, width: { xs: '100%', sm: '100%', md: '35%' } }}>
                    <AboutText txtContent={about2} />
                </Box>
                <Box sx={{ marginLeft: { md: 4 }, marginTop: { xs: 2, sm: 2 }, width: { xs: '100%', sm: '100%', md: '60%' } }}>
                    <AboutImage imgSrc={aboutImg2} />
                </Box>
            </Box>
        </Container>
    )
}

export default AboutPage;