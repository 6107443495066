import { Typography, Link } from "@mui/material"

export const about1 = <>
    <Typography variant="h6">
        Cześć!
    </Typography>
    <br />
    <Typography variant="body1" sx={{ width: '100%' }}>
        Mam na imię Marta. Swoją przygodę z fotografią zaczęłam ładnych parę lat temu. Robienie zdjęć zawsze sprawiało mi dużo radości. Zostało to zauważone przez moich
        znajomych i tak oto na urodziny dostałam lustrzankę, z którą praktycznie się nie rozstawałam! Podziwiała ze mną mnóstwo wschodów i zachodów słońca, które najbardziej
        lubiłam fotografować. Przejechała ze mną setki kilometrów na rowerze. Zwiedziła wiele ciekawych miejsc.
    </Typography >
    <br />
</>


export const about2 = <>
    <Typography variant="h6">
        Skąd pomysł na fotografię zwierząt?
        <hr />
    </Typography>
    <Typography variant="body1" sx={{ width: '100%' }}>
        Rodzice mówią, że gdy byłam mała i zobaczyłam coś, co miało cztery łapy i ogon od razu biegłam z wyciągniętymi rączkami.
        Można więc powiedzieć, że praca z czworonogami od zawsze była moim marzeniem. W swoje zdjęcia wkładam dużo serca i zaangażowania. Każdy pupil może czuć się
        w moim domowym studio jak u siebie.
        <br /><br />
        Nie ograniczam się tylko do psów czy kotów - chętnie sfotografuję chomika, agamę lub inne stworzonko, które jest członkiem Twojej rodziny.
    </Typography>
    <br /><br/>
    <Typography variant="h6" >
        Przepis na jeszcze więcej radości
        <hr />
    </Typography>
    <Typography variant="body1" sx={{ width: '100%' }}>
        Robiąc zdjęcia pomagam także bezdomnym zwierzętom. Dzięki fotografiom wykonanym w studio mogą koncertowo zaprezentować się w swoich ogłoszeniach adopcyjnych.
        Współpracuję
        z <Link
            underline="hover"
            href={'https://www.facebook.com/adoptujradysiaka'}
            color={'inherit'}
            sx={{ fontWeight: 'bold' }}>domami tymczasowymi w okolicach Trójmiasta
        </Link> oraz <Link
            underline="hover"
            href={'https://www.facebook.com/stowarzyszenie.paka.dla.zwierzaka'}
            color={'inherit'}
            sx={{ fontWeight: 'bold' }}>Stowarzyszeniem Paka dla Zwierzaka</Link>.
    </Typography>
</>