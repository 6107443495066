import { Box, Typography } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PlaceIcon from '@mui/icons-material/Place';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const ContactData = () => {
    return (
        <>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Dane teleadresowe
                <hr />
            </Typography>
            <Box sx={{ display: 'flex' }}>
                <PlaceIcon fontSize='medium' />
                <Typography variant="body1" sx={{ marginLeft: 1 }}>al. Jana Pawła II 4, Gdańsk Zaspa</Typography>
            </Box>
            <Box sx={{ marginTop: 2, display: 'flex' }}>
                <AlternateEmailIcon fontSize='medium' />
                <Typography variant="body1" sx={{ marginLeft: 1 }}>studio@ladypet.pl</Typography>
            </Box>
            <Box sx={{ marginTop: 2, display: 'flex' }}>
                <LocalPhoneIcon fontSize='medium' />
                <Typography variant="body1" sx={{ marginLeft: 1 }}>533 287 089</Typography>
            </Box>
            <Typography variant="h6" sx={{ marginBottom: 2, marginTop: 4 }}>
                Social Media
                <hr />
            </Typography>
            <Box sx={{ marginTop: 2, display: 'flex' }}>
                <InstagramIcon fontSize='medium' />
                <Typography variant="body1" sx={{ marginLeft: 1 }}>ladypetstudio</Typography>
            </Box>
            <Box sx={{ marginTop: 2, display: 'flex' }}>
                <FacebookIcon fontSize='medium' />
                <Typography variant="body1" sx={{ marginLeft: 1 }}>lady.pet.studio</Typography>
            </Box>
        </>
    )
}

export default ContactData;