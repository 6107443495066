import { Box, Typography } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import logo from '../../../assets/img/logo.png';

const Map = () => {
    return (
        <Box style={{ width: '100%', height: '100%' }}>
            <MapContainer
                center={[54.39123822341077, 18.59908035621124]}
                zoom={13}
                style={{ height: '100%', width: '100%' }}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[54.39123822341077, 18.59908035621124]}>
                    <Popup>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img alt={'logo'} src={logo} width={'25px'} height={'25px'} />
                            <Typography variant='caption' sx={{ ml: 1.3, fontSize: 'medium' }}>
                                Lady Pet Studio
                            </Typography>
                        </Box>
                    </Popup>
                </Marker>
            </MapContainer>
        </Box>
    )
}

export default Map;