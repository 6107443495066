import { Paper } from '@mui/material';

interface Props {
    imgSrc: string;
}

const AboutImage = (props: Props) => {
    return (
        <Paper sx={{ padding: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} elevation={8}>
            <img alt={'about'} src={props.imgSrc} width={'100%'}/>
        </Paper>
    )
}

export default AboutImage;