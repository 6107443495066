import { FooterContent } from './components';

const Footer = () => {
    return (
        <footer
            style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                minHeight: '64px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                backgroundColor: 'white',
                zIndex: 9999,
                boxShadow: '0 -7px 7px -7px rgba(0, 0, 0, 0.3)',
                padding: '7px'
            }}
        >
            <FooterContent />
        </footer>
    )
}

export default Footer;