import { createTheme, PaletteColor, PaletteColorOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    white?: PaletteColor;
    bckground?: PaletteColor;
  }

  interface PaletteOptions {
    white?: PaletteColorOptions;
    bckground?: PaletteColorOptions;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#c49949',
    },
    secondary: {
      main: '#b43f37',
    },
    white: {
      main: '#ffffff'
    },
    bckground: {
      main: '#262629'
    }
  },
});