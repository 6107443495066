import { Box, Typography } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const FooterContactInfo = () => {
    return (
        <Box sx={{ marginRight: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <AlternateEmailIcon fontSize='small' />
                <Typography variant="caption" sx={{ marginRight: 2, marginLeft: 0.7 }}>studio@ladypet.pl</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <LocalPhoneIcon fontSize='small' />
                <Typography variant="caption" sx={{ marginLeft: 0.7 }}>533 287 089</Typography>
            </Box>
        </Box >
    )
}

export default FooterContactInfo