import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { SocialMediaIcons } from '../../../components';

const LandingContent = () => {
    const navigate = useNavigate();

    const openSite = () => navigate('/portfolio');

    return (
        <>
            <Box sx={{
                position: 'fixed',
                top: 5,
                left: 0,
                marginLeft: '2.5vw',
                marginRight: '2.5vw',
                width: '95vw',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <Typography sx={{ fontSize: 'x-large', fontFamily: 'Nothing You Could Do, cursive' }} >
                    Lady Pet Studio
                </Typography>
                <SocialMediaIcons iconSize={'large'} />
            </Box>
            <Box sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}>
                <Button
                    endIcon={<ArrowForwardIcon />}
                    variant={'contained'}
                    size={'large'}
                    color={'inherit'}
                    onClick={openSite}
                    sx={{
                        fontFamily: 'Roboto Flex, sans-serif',
                        fontSize: 'large',
                        fontWeight: '600',
                        border: 2,
                        backgroundColor: 'rgba(120,120,120,0.3)',
                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                        "&:hover": {
                            cursor: 'pointer',
                            transform: 'scale(1.1)',
                            transition: 'transform 150ms ease-in-out',
                            backgroundColor: 'rgba(120,120,120,0.3)'
                        }
                    }}
                >
                    ZAJRZYJ PO WIĘCEJ
                </Button>
            </Box>
        </>
    )
}

export default LandingContent;