interface Props {
    txtContent: React.ReactNode;
}

const AboutText = (props: Props) => {
    return (
        <>
            {props.txtContent}
        </>
    )
}

export default AboutText;