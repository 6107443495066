import img00 from '../../../assets/img/gallery/img00.jpg'
import img01 from '../../../assets/img/gallery/img01.jpg'
import img02 from '../../../assets/img/gallery/img02.jpg'
import img03 from '../../../assets/img/gallery/img03.jpg'
import img04 from '../../../assets/img/gallery/img04.jpg'
import img05 from '../../../assets/img/gallery/img05.jpg'
import img06 from '../../../assets/img/gallery/img06.jpg'
import img07 from '../../../assets/img/gallery/img07.jpg'
import img08 from '../../../assets/img/gallery/img08.jpg'
import img09 from '../../../assets/img/gallery/img09.jpg'
import img10 from '../../../assets/img/gallery/img10.jpg'
import img11 from '../../../assets/img/gallery/img11.jpg'
import img12 from '../../../assets/img/gallery/img12.jpg'
import img13 from '../../../assets/img/gallery/img13.jpg'
import img14 from '../../../assets/img/gallery/img14.jpg'
import img15 from '../../../assets/img/gallery/img15.jpg'
import img16 from '../../../assets/img/gallery/img16.jpg'
import img17 from '../../../assets/img/gallery/img17.jpg'
import img18 from '../../../assets/img/gallery/img18.jpg'
import img19 from '../../../assets/img/gallery/img19.jpg'
import img20 from '../../../assets/img/gallery/img20.jpg'
import img21 from '../../../assets/img/gallery/img21.jpg'
import img22 from '../../../assets/img/gallery/img22.jpg'

interface ImgData {
    src: string;
    rows?: number;
    cols?: number;
}

export const gallery: ImgData[] = [
    {
        src: img00,
    },
    {
        src: img01,
    },
    {
        src: img02,
    },
    {
        src: img03,
    },
    {
        src: img04
    },
    {
        src: img05,
    },
    {
        src: img06
    },
    {
        src: img07
    },
    {
        src: img08
    },
    {
        src: img09
    },
    {
        src: img10
    },
    {
        src: img11
    },
    {
        src: img12
    },
    {
        src: img13
    },
    {
        src: img14
    },
    {
        src: img15
    },
    {
        src: img16
    },
    {
        src: img17
    },
    {
        src: img18
    },
    {
        src: img19
    },
    {
        src: img20
    },
    {
        src: img21
    },
    {
        src: img22
    },
];