import slide01 from '../../../assets/img/slider/slide01.jpg';
import slide02 from '../../../assets/img/slider/slide02.jpg';
import slide03 from '../../../assets/img/slider/slide03.jpg';
import slide04 from '../../../assets/img/slider/slide04.jpg';

export const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const images = [
    {
        source: slide01,
    },
    {
        source: slide02,
    },
    {
        source: slide03,
    },
    {
        source: slide04,
    },
];