
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { responsive, images } from './slider-consts';

const Slider = () => {
    return (
        <Carousel
            showDots
            infinite
            autoPlay
            autoPlaySpeed={1500}
            responsive={responsive}
            transitionDuration={2000}
            customTransition='transform 2000ms ease-in-out'
            removeArrowOnDeviceType={['mobile']}
        >
            {images.map((slide, index) => (
                <div key={index} style={{ background: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', height: '100%', width: '100%', }}>
                    <img alt={`slide${index}`} src={slide.source} style={{ opacity: '0.8',height: '100vh', width: '100vw', display: 'block', marginLeft: 'auto', marginRight: 'auto', objectFit: 'cover' }} />
                </div>
            ))
            }
        </Carousel >
    )
}

export default Slider;