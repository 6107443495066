import { Box } from '@mui/material';

import { Slider, LandingContent } from '../components';

const Landing = () => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: '0',
                left: '0',
                zIndex: 9999,
                width: '100vw',
                height: '100vh',
            }}
        >
            <Slider />
            <LandingContent />
        </Box>
    )
}

export default Landing;